/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var APP = {
		// All pages 
		$el : {
			'window' : $(window),
			'body' : $(document.body),
		},
		'common': {
			init: function() {
				// JavaScript to be fired on all pages
				setupBanners();
				setupNav();
				setupPackageItems();

				$('.popup-image').magnificPopup({type:'image'});

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = APP;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	function setupBanners(){

		$('#main-banner, .metaslider, .page-banner .carousel').each(function(index, el) {
			
			var $banner = $(el);

			if($banner.data('banner-ready')){
				return this;
			}
			$banner.data('banner-ready', true);
			var	$inner = $('.carousel-inner, .rslides', $banner),
				$imgs = $('img', el),
				banner_ratio,
				images_ratio = [],
				checkBgRatio = function(){
					var w = $banner.width(),
						h = $banner.height();
					banner_ratio = w / h;
					$imgs.filter(':visible').each(function(){
						var $img = $(this),
							src = $img.attr('src');
						if(images_ratio[src]){
							checkBgRatioPrivate($img, src);
						} else {
							$img.off('load.ratio').one('load.ratio', function(){
								checkBgRatioPrivate($img);
							});

							if(this.complete){
								$img.trigger('load.ratio');
							}
							
						}
					});
				},
				checkBgRatioPrivate = function($img, _src){
					var src = _src ? _src : $img.attr('src'),
						saved_ratio = images_ratio[src],
						image_ratio = saved_ratio ? saved_ratio : $img.width() / $img.height();
					images_ratio[src] = image_ratio;
					if(banner_ratio > image_ratio){
						$img.parent().addClass('wider');
					} else {
						$img.parent().removeClass('wider');
					}
				},
				checkBgRatioThrottle = _.throttle(checkBgRatio, 100),
				setImagesPosition = function(){
					var s_top = APP.$el.window.scrollTop(),
						offset = $inner.offset();

					if(offset){

						var top = offset.top,
							y = Math.max(0, s_top - top);

						$imgs.css({
							'-webkit-transform':'translate(0,' + y + 'px)',
							'-ms-transform':'translate(0,' + y + 'px)',
							'transform':'translate(0,' + y + 'px)'
						});
					}
				};
				console.log($inner, $imgs);

			if(!$inner.length){
				$inner = $('.image', $banner).first();
			}


			APP.$el.window.on('resize.ratio', checkBgRatioThrottle);

			APP.$el.window.on('scroll.banner-position', setImagesPosition);

			$banner
				.on('slide.bs.carousel', function(){
					$banner.addClass('sliding');
					setTimeout(checkBgRatio, 100);
					setupBanners();
				})
				.on('slid.bs.carousel', function(){
					$banner.removeClass('sliding');
				});
			checkBgRatio();
			setImagesPosition();
		});
	}

	function setupNav(){
		var $nav = $('#main-nav'),
			nav_top = $nav.offset().top,
			saveNavTop = function(){
				nav_top = $nav.offset().top;
			},
			setNavPosition = function(){
				var s_top = APP.$el.window.scrollTop();

				if(s_top > nav_top){
					APP.$el.body.addClass('fixed-nav');
					APP.$el.body.addClass('fixed-nav');
				} else {
					APP.$el.body.removeClass('fixed-nav');
					APP.$el.body.removeClass('fixed-nav');
				}

			};

			APP.$el.window.on('resize.nav', saveNavTop);

			APP.$el.window.on('scroll.nav', setNavPosition);

	}

	function setupPackageItems(){
		$('.package-items').masonry();
	}

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
